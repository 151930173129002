import React, { useEffect, useState } from 'react';

const IndependenceDayNotice = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className={`notice-container ${isVisible ? 'visible' : ''}`}>
      <div className="notice-card">
        <div className="notice-content">
          <h1 className="notice-title">Special Notice</h1>
          <p className="notice-date">Happy Independence Day, Finland! 🇫🇮</p>

          <div className="menu-section">
            <h2 className="section-title">Opening Hours</h2>
            <p>Friday: 12:00 PM - 9:00 PM</p>
            <p>Note: No lunch buffet available, à la carte menu only</p>
          </div>

          <div className="menu-section">
            <h2 className="section-title">Special Offers</h2>
            <p>🎉 10% discount on all main course dishes!</p>
            
            <h3 className="section-title">Drink Specials</h3>
            <ul className="menu-list">
              <li>Hana olut 4.6% - 6€</li>
              <li>Gurkha Nepalese beer (330ml) - 5.5€</li>
              <li>Nepalese Mango Lassi - 4€</li>
            </ul>
          </div>
        </div>
      </div>

      <style>{`
        .notice-container {
          max-width: 600px;
          margin: 20px auto;
          padding: 20px;
          opacity: 0;
          transform: translateY(-20px);
          transition: all 1s ease-out;
          font-family: "Poppins", serif;
        }

        .notice-container.visible {
          opacity: 1;
          transform: translateY(0);
        }

        .notice-card {
          background: linear-gradient(135deg, #fff3e6, #ffe4cc);
          border-radius: 15px;
          box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          padding: 2px;
        }

        .notice-content {
          background: rgba(255, 255, 255, 0.9);
          border-radius: 13px;
          padding: 25px;
          text-align: center;
        }

        .notice-title {
          color: #ff6b00;
          font-size: 2em;
          font-weight: bold;
          margin: 0 0 10px 0;
          animation: bounce 2s infinite;
        }

        .notice-date {
          color: #ff8533;
          font-size: 1.2em;
          font-weight: 600;
          margin-bottom: 25px;
        }

        .menu-section {
          background: rgba(255, 243, 230, 0.7);
          border-radius: 10px;
          padding: 20px;
          margin: 15px 0;
          transition: transform 0.3s ease;
        }

        .menu-section:hover {
          transform: scale(1.02);
        }

        .section-title {
          color: #cc5500;
          font-size: 1.4em;
          margin-bottom: 15px;
        }

        .menu-list {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .menu-list li {
          color: #ff751a;
          padding: 8px 0;
          transition: color 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }

        .menu-list li::before {
          content: "•";
          color: #ff8533;
        }

        .menu-list li:hover {
          color: #cc5500;
        }

        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-20px);
          }
          60% {
            transform: translateY(-10px);
          }
        }

        @media (max-width: 480px) {
          .notice-container {
            padding: 15px;
            margin-top: 100px;
          }

          .notice-title {
            font-size: 1.5em;
          }

          .notice-date {
            font-size: 1em;
          }

          .section-title {
            font-size: 1.2em;
          }
        }
      `}</style>
    </div>
  );
};

export default IndependenceDayNotice;