import React, { useEffect } from "react";
import { MenuItem, Navbar } from "../components";
import { Footer, Header, Intro } from "../container";

import OpeningTime from "../components/Weekupdate/OpeningTime";
import TodayLunch from "../container/Menu/TodayLunch";
import Cards from "../container/ThePortions/Cards";

import Gallery from "../components/Gallery/Gallery";
import BookTable from "../container/BookTable/BookTable";
import Cart from "../container/Cart/Cart";
import EventNotice from "../container/ThePortions/EventNotice";
import { useDispatch, useSelector } from "react-redux";
import { setAlacarte, setLunch, setWeek } from "../State/State";
import Notice from "../container/Notice/Notice"
import SpecialOfferNotice from "../container/Notice/SpecialOfferNotice";


const Home = () => {
  const weekDay = useSelector(state => state.weekDay)
  const dispatch = useDispatch()
  const lunch = useSelector(state => state.lunch)

  const fetchalacarte= async (req, res) => {
    try {
        const response = await fetch("https://akhanda-backend.onrender.com/api/alacarte", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        dispatch(setAlacarte({alacarte:data}))
        
    } catch (error) {
        console.error('Error fetching data:', error);
        
    }
};
  useEffect(() => {
    fetchalacarte()
    fetch("https://akhanda-backend.onrender.com/api/lounas",{method:"GET",headers:{"Content-Type": "application/json"}})
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse the JSON data
      })
      .then((data) => {
        const sortedData = data.sort((a, b) => {
          return parseInt(a.index) - parseInt(b.index);
        });
        
        dispatch(setLunch({lunch :sortedData}))
        
         // Store data in state
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
    
  }, []);
  useEffect(() => {
    var date = new Date();

    // Define opening hours for each day
    const options = { timeZone: 'Europe/Helsinki' };

    // Format the date in the Finnish timezone
    const formattedDate = new Date(date.toLocaleString('en-US', options));

    // Get the day of the week (0-6) in Finland timezone
    const dayIndex = formattedDate.getDay() - 1;
  dispatch(setWeek({weekDay : dayIndex}))
}, []);
  return (
    <div>
      <Navbar />
      <EventNotice isHome={true} />
      <MenuItem />
      <Header />
      <Intro />
      <div className="responsive-wrapper">
      <SpecialOfferNotice />
      <style>{`
        .responsive-wrapper {
          
          
          display: none;
          
          
          
          
        }

        @media (max-width: 768px) {
          .responsive-wrapper {
          display: flex;
            
            align-items: flex-start;
           
          }
        }

        @media (max-width: 480px) {
          .responsive-wrapper {
            margin: 5px;
          }
        }
      `}</style>
    </div>
      <BookTable />
      <OpeningTime />
      
      <Notice />
      {weekDay<5 && <TodayLunch />}
      <Cards />
      <Gallery  />
      <Footer />
      
    </div>
  );
};

export default Home;
