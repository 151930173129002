import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./BookTable.css";
import tablesidepicture from "../../assets/tableformside.png";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setBooking } from "../../State/State";
import SpecialMenu from "../Menu/SpecialMenu";
import SpecialOfferNotice from "../Notice/SpecialOfferNotice";

const BookTable = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [guests, setGuests] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate()
  const booking = useSelector(state => state.booking)
  const dispatch = useDispatch()
  const inEnglish = useSelector(state => state.inEnglish)

  const SERVICE_ID = "service_7z3s43e";
  const USER_ID = "RkwEP1HJp62wHwjPE";
  const TEMPLATE_ID = "template_wmiy0gt";

  const handleSubmit = (e) => {
   
    e.preventDefault();

    const trimmedName = name.trim();
    const trimmedEmail = email.trim();
    const trimmedDate = date.trim();
    const trimmedTime = time.trim();
    const trimmedGuests = guests.trim();
    const trimmedPhone = phone.trim();
    const trimmedMessage = message.trim();
    const isMayDay =
      new Date(date).getMonth() === 4 && new Date(date).getDate() === 1;

    if (
      !trimmedName ||
      !trimmedEmail ||
      !trimmedDate ||
      !trimmedTime ||
      !trimmedGuests ||
      !trimmedPhone
    ) {
      alert("All fields must be filled out and cannot contain only spaces.");
      return;
    }

    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(trimmedPhone)) {
      setPhoneError("Please enter a valid phone number.");
      return;
    }
    const verify = window.confirm(`Book a table for ${trimmedGuests}?`);
    if (!verify) return;

    setPhoneError("");
    const bookingDetails = {
      name: trimmedName,
      email: trimmedEmail,
      date: trimmedDate,
      orderTime: trimmedTime,
      guests: trimmedGuests,
      b:true,
      
      
    };
    const adminBookingDetails = {
      name: trimmedName,
      email: "akhandanepalilainenravintola@gmail.com",
      customerEmail:email,
      date: trimmedDate,
      orderTime: trimmedTime,
      guests: trimmedGuests,
      phone: trimmedPhone,
      message: trimmedMessage,
      b:true
      
    };
    emailjs.send(SERVICE_ID, TEMPLATE_ID, bookingDetails, USER_ID).then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
      },
      (err) => {
        console.log("FAILED...", err);
      }
    );

    emailjs.send(SERVICE_ID, "template_f6crjhu", adminBookingDetails, USER_ID).then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        dispatch(setBooking({booking:true}))
      },
      (err) => {
        console.log("FAILED...", err);
      }
    );

    setName("");
    setEmail("");
    setDate("");
    setTime("");
    setGuests("");
    setPhone("");
    navigate("/success")
  };

  return (
    <div className="App">
      <div data-aos="fade-up" className="table__booking-sideimg">
        <SpecialOfferNotice/>
      </div>
      <div data-aos="fade-right" className="table-booking">
        <h2>{inEnglish?"Book a Table":"VARAA PÖYTÄ"} </h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="name">{inEnglish?"Name":"Nimi"}</label>
              <input
                type="text"
                id="name"
                placeholder="Kirjoita nimesi"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">{inEnglish?"Email":"Sähköposti"}</label>
              <input
                type="email"
                id="email"
                placeholder={inEnglish?"Enter your email":"Syötä sähköpostiosoitteesi"}
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="phone">{inEnglish?"Contact":"Yhteysnumero"}</label>
              <input
                type="tel"
                id="phone"
                placeholder={inEnglish?"Enter your contact information":"Syötä yhteystietosi"}
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
              />
              {phoneError && <p className="error">{phoneError}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="guests">{inEnglish?"Number of Persons":"Henkilöiden määrä"}</label>
              <input
                type="number"
                id="guests"
                placeholder={inEnglish?"Number of Persons":"Henkilöiden määrä"}
                value={guests}
                required
                onChange={(e) => setGuests(e.target.value)}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                value={date}
                required
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="time">{inEnglish?"Time":"Aika"}</label>
              <input
                type="time"
                id="time"
                value={time}
                required
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="message">{inEnglish?"Message":"Viesti"}</label>
            <textarea
              rows={6}
              id="message"
              placeholder={inEnglish?"Write your message here":"Kirjoita viestisi tähän"}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <button type="submit">{inEnglish?"Send a Booking Request":"Lähetä Varauspyyntö"}</button>
        </form>
      </div>
    </div>
  );
};

export default BookTable;
